import React from "react"
import Page from "../components/page";
import RatingsGrid from "../components/ratingsgrid";
import { Link } from "gatsby";

const IndexPage = (props) => {
  const pageTitle="College Basketball Ratings"
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
      <h1>{pageTitle}</h1>
      <p><Link to="/">cbbratings.com</Link>'s system provides an objective, statistical analysis to rank NCAA college basketball teams. To learn more about the methodology click <Link to="/methodology">here</Link>
      </p>

      <RatingsGrid season="2324" />
    </Page>
    );
};
export default IndexPage;